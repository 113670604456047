import cn from "classnames";
import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as Yup from "yup";

import Button from "../../atoms/button/Button";
import { useLead, useSetLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Lead } from "../../features/leads-funnel/domain/lead";
import HappyCat from "../../images/organisms/health-questionnaire/happyCat.svg";
import HappyDog from "../../images/organisms/health-questionnaire/happyDog.svg";
import SickCat from "../../images/organisms/health-questionnaire/sickCat.svg";
import SickDog from "../../images/organisms/health-questionnaire/sickDog.svg";
import { PetSpecies } from "../../settings/pet";
import { Events, track } from "../../utils/analytics";
import { isAttributionQuestionPageAvailable } from "../../utils/locale-configuration-utils";
import { rollbar } from "../../utils/rollbar";
import PageStepForm from "../page-step-form/PageStepForm";
import { HealthQuestionnaire } from "./domain/health-questionnaire";
import * as styles from "./PreExistences.module.scss";
import {
  useHealthQuestionnaire,
  useSetHealthQuestionnaire,
} from "./use-cases/health-questionnaire-use-cases";

interface PreExistencesFormValues {
  preExistences: "no" | "yes";
}

const svgComponents = {
  [PetSpecies.cat]: {
    happyPet: <HappyCat />,
    sickPet: <SickCat />,
  },
  [PetSpecies.dog]: {
    happyPet: <HappyDog />,
    sickPet: <SickDog />,
  },
};

const PreExistences = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const setLead = useSetLead();
  const healthQuestionnaire: HealthQuestionnaire = useHealthQuestionnaire();
  const setHealthQuestionnaire = useSetHealthQuestionnaire();
  const { happyPet, sickPet } = lead.petSpecies
    ? svgComponents[lead.petSpecies]
    : { happyPet: <HappyDog />, sickPet: <SickDog /> };

  const PreExistencesData = {
    initialValues: {
      preExistences: healthQuestionnaire.preExistences,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      preExistences: Yup.string().trim().required(t("common.validation.required")),
    }),
    handleSubmit: async (
      values: PreExistencesFormValues,
      { setSubmitting }: FormikHelpers<PreExistencesFormValues>
    ): Promise<void> => {
      if (!setHealthQuestionnaire) {
        return;
      }

      try {
        setSubmitting(true);

        await setHealthQuestionnaire({
          preExistences: values.preExistences,
        });

        if (values.preExistences === "no") {
          const updatedLead = await setLead({
            ...lead,
            petDiseases: null,
          });

          if (!updatedLead) {
            setSubmitting(false);

            return;
          }
        }

        track(Events.FORM_ANSWERED, {
          healthQuestionnaire: {
            question: "Pre-existences",
            answer: values.preExistences,
          },
        });

        const nextUrl: string =
          values.preExistences === "yes"
            ? `/${i18n.language}/onboarding/health_questionnaire/diseases/`
            : isAttributionQuestionPageAvailable(lead.countryIso)
            ? `/${i18n.language}/onboarding/how_know_us/`
            : `/${i18n.language}/onboarding/email/`;

        void navigate(nextUrl);
      } catch (error) {
        rollbar.warn("Error in pre-existences form submission", error, {
          fingerprint: "health-questionnaire-errors",
          context: "health-questionnaire-pre-existences",
        });
      } finally {
        setSubmitting(false);
      }
    },
    children: (props: FormikProps<PreExistencesFormValues>) => {
      const { isSubmitting, isValid } = props;

      return (
        <Form className={cn(styles.preExistencesForm)}>
          <div className={cn(styles.happyPetSvgContainer)}>{happyPet}</div>
          <Field type="radio" name="preExistences" value="no" id="no" disabled={isSubmitting} />
          <label htmlFor="no" className={styles.happyPetLabel}>
            {t("health_questionnaire.pre_existences.answer.without_pre_existences")}
          </label>
          <div className={cn(styles.sickPetSvgContainer)}>{sickPet}</div>
          <Field type="radio" name="preExistences" value="yes" id="yes" disabled={isSubmitting} />
          <label htmlFor="yes" className={styles.sickPetLabel}>
            {t("health_questionnaire.pre_existences.answer.with_pre_existences")}
          </label>
          <Button type="submit" disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("health_questionnaire.pre_existences.question", { petName: lead.petName })}
      subtitle={t("health_questionnaire.pre_existences.subtitle")}
      formData={PreExistencesData}
    />
  );
};

export default PreExistences;
